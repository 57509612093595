import Header from '../Header';
import Container from './Container';

const AppHeader = props => {
  return (
    <header className='app-header'>
      <Container>
        {/* <Header as='h5' style={{marginBottom: 0}}>
          {props.header}
        </Header> */}
        <p>{props.header}</p>
        <Header as='h3'>
          {props.subheader}
        </Header>
      </Container>
    </header>
  );
};

export default AppHeader;
