import classes from '../styles/components/card.module.scss';
import { useDrag } from 'react-dnd';

const Card = props => {
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'card',
    item: { type: "card", id: props.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  return collected.isDragging ? (
    <div ref={dragPreview} className={classes.card} style={props.style ? {...props.style, cursor: 'move'} : {cursor: 'move'}}>
      {props.children}
    </div>
  ) : (
    <div ref={drag} className={classes.card} style={props.style ? {...props.style, cursor: 'grab'} : {cursor: 'grab'}}>
      {props.children}
    </div>
  );
}

export default Card;
