import { useState } from 'react';
import Card from './Card';
import Header from './Header';
import Button from './Button';
import TaskModal from './TaskModal';

const TaskCard = props => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
    {modalVisible && (
      <TaskModal
        setModalVisible={setModalVisible}
        title={props.title}
        status={props.status}
        desc={props.desc}
        acceptanceCriteria={props.acceptanceCriteria}
        assets={props.assets}
        tasks={props.tasks}
        setTasks={props.setTasks}
      />
    )}
    <Card id={props.id} style={props.style ? {...props.style, padding: '20px'} : {padding: '20px'}}>
      <Header as='h5'>{props.title}</Header>
      <img
        style={{maxWidth: '100%', marginBottom: '1rem', boxShadow: '0 0 4px 3px rgba(0,0,0, .1)'}}
        src={`https://frontend-tech-screen.s3.amazonaws.com/${props.assets[0]}`}
        alt=''
      />
      <Button onClick={() => setModalVisible(true)}>View Details</Button>
    </Card>
    </>
  );
}

export default TaskCard;
