import { Route, Switch } from "react-router-dom";
// import Completed from "./Routes/Completed";

// PAGES
import Home from "./Routes/Home";
import NotFound from "./Routes/NotFound";
import Submit from "./Routes/Submit";

export default (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/submit" component={Submit} />
    {/* <Route exact path="/completed" component={Completed} /> */}
    <Route component={NotFound} />
  </Switch>
);
