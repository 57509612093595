import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TASK_LIST } from "../util/taskList";
import ThreeColumnContainer from "../Components/layout/ThreeColumnContainer";
import Container from "../Components/layout/Container";
import DropColumn from "../Components/layout/DropColumn";
import AppHeader from "../Components/layout/AppHeader";
import TaskCard from "../Components/TaskCard";
import Celebration from "../Components/Celebration";
import Header from "../Components/Header";
import CompleteModal from "../Components/CompleteModal";
import Button from "../Components/Button";

const Home = (props) => {
  const history = useHistory();
  const [tasks, setTasks] = useState(TASK_LIST);
  const [celebrate, setCelebrate] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const changeTaskStatus = useCallback(
    (id, status) => {
      const newTasks = [...tasks];

      newTasks.find((task) => {
        if (task.id === id) {
          return (task.status = status);
        }
        return null;
      });
      setTasks(newTasks);
    },
    [tasks]
  );

  useEffect(() => {
    if (!tasks.some((task) => task.status !== "done")) {
      setCelebrate(true);
      setTimeout(() => setModalOpen(true), 2000);
    } else {
      setCelebrate(false);
    }
  }, [tasks]);

  return (
    <div className="home">
      <AppHeader header="Assemble" />
      {celebrate && <Celebration />}
      {modalOpen && (
        <CompleteModal onClose={() => setModalOpen(false)}>hi</CompleteModal>
      )}
      <Container>
        <DndProvider backend={HTML5Backend}>
          <Header
            as="h1"
            color="primary"
            textAlign="center"
            style={{ marginBottom: "30px" }}
          >
            Musical Sweatpants
          </Header>
          <p
            style={{
              textAlign: "center",
              margin: "auto",
              maxWidth: "720px",
              lineHeight: 2,
            }}
          >
            The client gave a last minute deadline to complete the app today!
            Below you'll find tasks that need to be completed in order to ship
            the Musical Sweatpants app to eager buyers. Complete the tasks to
            the best of your ability within the specified deadline and then hit
            the "Submit your work" button.
          </p>
          <Header as="h4" textAlign="center" style={{ marginBottom: "50px" }}>
            Good luck!
          </Header>
          <Header as="h4" textAlign="center" style={{ marginBottom: "50px" }}>
            <Button onClick={() => history.push("/submit")}>
              Submit your work
            </Button>
          </Header>
          <ThreeColumnContainer
            columnOne={{
              title: "Backlog",
              content: (
                <DropColumn
                  status="backlog"
                  changeTaskStatus={changeTaskStatus}
                >
                  {tasks
                    .filter((item) => item.status === "backlog")
                    .map((item) => (
                      <TaskCard
                        key={item.id}
                        id={item.id}
                        style={{ marginBottom: "20px" }}
                        title={item.title}
                        status={item.status}
                        desc={item.desc}
                        assets={item.assets}
                        acceptanceCriteria={item.acceptanceCriteria}
                        tasks={tasks}
                        setTasks={setTasks}
                      />
                    ))}
                </DropColumn>
              ),
            }}
            columnTwo={{
              title: "In Progress",
              content: (
                <DropColumn
                  status="in-progress"
                  changeTaskStatus={changeTaskStatus}
                >
                  {tasks
                    .filter((item) => item.status === "in-progress")
                    .map((item) => (
                      <TaskCard
                        key={item.id}
                        id={item.id}
                        style={{ marginBottom: "20px" }}
                        title={item.title}
                        status={item.status}
                        desc={item.desc}
                        assets={item.assets}
                        acceptanceCriteria={item.acceptanceCriteria}
                        tasks={tasks}
                        setTasks={setTasks}
                      />
                    ))}
                </DropColumn>
              ),
            }}
            columnThree={{
              title: "Done",
              content: (
                <DropColumn status="done" changeTaskStatus={changeTaskStatus}>
                  {tasks
                    .filter((item) => item.status === "done")
                    .map((item) => (
                      <TaskCard
                        key={item.id}
                        id={item.id}
                        style={{ marginBottom: "20px" }}
                        title={item.title}
                        status={item.status}
                        desc={item.desc}
                        assets={item.assets}
                        acceptanceCriteria={item.acceptanceCriteria}
                        tasks={tasks}
                        setTasks={setTasks}
                      />
                    ))}
                </DropColumn>
              ),
            }}
          />
        </DndProvider>
      </Container>
    </div>
  );
};

export default Home;
