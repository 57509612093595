export const TASK_LIST = [
  {
    id: 'task-1',
    status: 'backlog',
    title: 'Implement size selection functionality',
    desc: "As a user, I want to be able to select the size of the sweatpants I want to purchase so that I don't order sweatpants for ants.",
    assets: ['wireframe4.png', 'wireframe3.png', 'musical-sweatpants-demo.mp4'],
    acceptanceCriteria: [
      {id: 'ac-1', label: 'Render the buttons for different clothing sizes', complete: false},
      {id: 'ac-2', label: 'Add click handlers to the buttons', complete: false},
      {id: 'ac-3', label: 'Save the clothing size in state/context', complete: false},
      {id: 'ac-4', label: 'Add conditional styling on selected size option', complete: false},
      {id: 'ac-5', label: 'Disable "Add to Cart" button until a size is selected', complete: false}
    ],
  },
  {
    id: 'task-2',
    status: 'backlog',
    title: 'Implement reusable Image component',
    desc: "As a user, I want to be able to see how amazing these sweatpants actually are so I know I'm getting top-tier musical sweatpants and not the knock off ones you always see around.",
    assets: ['wireframe1.png', 'frontside@3x.png', 'frontside@2x.png', 'frontside.png'],
    acceptanceCriteria: [
      {id: 'ac-1', label: 'Render the front image of the sweatpants product', complete : false},
      {id: 'ac-2', label: 'Render the back image of the sweatpants product', complete : false},
      {id: 'ac-3', label: 'Add CSS for image container and positioning', complete : false},
      {id: 'ac-4', label: 'Bonus: overlay the album artwork and name', complete : false},
      {id: 'ac-5', label: 'Bonus: display the correct image resolution based on screen', complete : false}
    ]
  },
  {
    id: 'task-3',
    status: 'backlog',
    title: 'Implement form input onChange handlers',
    desc: "As a user, I want to be able to update the form inputs with my information so that I can get these top-tier musical sweatpants shipped to my house and on my body as soon as humanly possible.",
    assets: ['wireframe7.png', 'wireframe6.png', 'wireframe5.png', 'musical-sweatpants-demo.mp4'],
    acceptanceCriteria: [
      {id: 'ac-1', label: 'Create a single onChange handler that can manage changes to all form inputs', complete: false},
      {id: 'ac-2', label: 'Add validation to the forms', complete: false}
    ]
  },
];
