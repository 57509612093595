import Header from '../Header';
import Flex from './Flex';
import classes from '../../styles/components/three-column-container.module.scss';

const ThreeColumnContainer = props => {
  return (
    <div className={classes['three-column-container']}>
      <Flex>
        <Flex.Column basis='33.333%'>
          <Header textAlign='centered' as='h3'>
            {props.columnOne.title}
          </Header>
          {props.columnOne.content}
        </Flex.Column>
        <Flex.Column basis='33.333%'>
          <Header textAlign='centered' as='h3'>
            {props.columnTwo.title}
          </Header>
          {props.columnTwo.content}
        </Flex.Column>
        <Flex.Column basis='33.333%'>
          <Header textAlign='centered' as='h3'>
            {props.columnThree.title}
          </Header>
          {props.columnThree.content}
        </Flex.Column>
      </Flex>
    </div>
  );
};

ThreeColumnContainer.defaultProps = {
  verticalAlign: 'middle',
};

export default ThreeColumnContainer;
