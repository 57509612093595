import PropTypes from 'prop-types';
import classNames from 'classnames';
import Flex from './layout/Flex';
import classes from '../styles/components/checkbox.module.scss';
import labelClasses from '../styles/components/label.module.scss';

const Checkbox = (props) => {
  const {
    checked,
    disabled,
    id,
    label,
    tabIndex,
    type,
    value,
    onChange,
    labelClassName,
    className,
    indeterminate,
    fitted,
    slider,
    toggle,
    ...rest
  } = props;

  const inputClasses = [
    classes.ui,
    classes.checked,
    classes.disabled,
    classes.indeterminate,
    classes.fitted,
    classes.slider,
    classes.toggle,
    classes.checkbox,
    className,
  ];

  const labelClassNames = [labelClasses.ui, labelClasses.label, labelClassName];
  return (
    <Flex alignment="center">
      <input
        id={id}
        className={classNames(inputClasses)}
        checked={checked}
        disabled={disabled}
        tabIndex={tabIndex}
        type={type}
        value={value}
        onChange={onChange}
        {...rest}
      />
      <label className={classNames(labelClassNames)} htmlFor={id}>
        {label}
      </label>
    </Flex>
  );
};

Checkbox.defaultProps = {
  type: 'checkbox',
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

export default Checkbox;
