import classNames from "classnames";
import classes from "../styles/components/button.module.scss";

const Button = (props) => {
  const {
    fullwidth,
    color,
    size,
    basic,
    circular,
    compact,
    fluid,
    icon,
    inverted,
    loading,
    negative,
    positive,
    primary,
    secondary,
    animated,
    className,
    onClick,
    type,
    role,
    tabIndex,
    children,
  } = props;

  const buttonClasses = [
    classes.ui,
    classes.button,
    color && classes.color,
    size && classes.size,
    basic && classes.basic,
    circular && classes.circular,
    compact && classes.compact,
    fluid && classes.fluid,
    icon && classes.icon,
    inverted && classes.inverted,
    loading && classes.loading,
    negative && classes.negative,
    positive && classes.positive,
    primary && classes.primary,
    secondary && classes.secondary,
    animated && classes.animated,
    className && className,
    fullwidth && classes.fullwidth,
  ];

  return (
    <button
      className={classNames(buttonClasses)}
      onClick={onClick}
      type={type}
      role={role}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  role: "button",
  tabIndex: 0,
  type: "button",
  disabled: false,
};

export default Button;
