import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AriaModal from "react-aria-modal";
import Button from "./Button";
import classes from "../styles/components/modal.module.scss";

const Modal = (props) => {
  const modalClasses = [
    props.fullscreen && classes["modal__content--fullscreen"],
    classes.modal__content,
    props.headerImage && classes["modal__content--multicolumn"],
  ];

  return (
    <AriaModal
      onExit={props.onClose}
      titleText={props.ariaLabel}
      applicationNode={document.getElementById("root")}
      underlayColor={false}
      underlayClass={classes["modal-wrapper"]}
      dialogClass={classes.modal}
      verticallyCenter
      scrollDisabled
    >
      <div className={classNames(modalClasses)}>
        {props.headerImage && (
          <div className={classes.modal__column}>
            <img src={props.headerImage} alt="" />
          </div>
        )}
        <div className={classes.modal__column}>
          {/* <div> */}
          {props.children}
          {/* </div> */}
        </div>
        <Button
          className={classes.modal__button}
          onClick={props.onClose}
          color="transparent"
        >
          <span className={classes["visually-hidden"]}>Close Modal</span>×
        </Button>
      </div>
    </AriaModal>
  );
};

Modal.defaultProps = {
  ariaLabel: "Modal",
};

Modal.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  headerImage: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default Modal;
