import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from '../styles/components/header.module.scss';
import React from 'react';

const Header = (props) => {
  const {
    as,
    attached,
    block,
    children,
    className,
    color,
    disabled,
    dividing,
    floated,
    icon,
    image,
    inverted,
    size,
    subheader,
    textAlign,
    style
  } = props;
  const headerClasses = [
    classes.header,
    color && classes[color],
    size && classes[size],
    block && classes.block,
    disabled && classes.disabled,
    dividing && classes.dividing,
    floated && classes.floated,
    icon && classes[icon],
    image && classes[image],
    inverted && classes.inverted,
    attached && classes.attached,
    textAlign && classes[textAlign],
    className
  ];

  return React.createElement(
    as,
    {className: classNames(headerClasses), style},
    [
      children,
      subheader && subheader
    ]);
};

Header.defaultProps = {
  as: 'h1',
};

Header.propTypes = {
  as: PropTypes.elementType,
  block: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dividing: PropTypes.bool,
  size: PropTypes.oneOf(['mini', 'small', 'medium', 'large', 'extraLarge']),
  subheader: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'centered', 'right']),
  style: PropTypes.object
};

export default Header;
