import React from "react";
import Header from "./Header";
import Modal from "./Modal";
import Lottie from "react-lottie";
import congratulationsData from "../lotties/congratulations.json";
import Button from "./Button";
import { useHistory } from "react-router-dom";

const CompleteModal = ({ onClose }) => {
  const history = useHistory();
  return (
    <Modal onClose={onClose}>
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <Header
            as="h1"
            color="primary"
            textAlign="center"
            style={{ marginBottom: "30px" }}
          >
            Nice work
          </Header>
          <div style={{ margin: "50px 0" }}>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: congratulationsData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
            />
          </div>
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            Looks like you've completed all the tasks! Let's get your awesome
            work submitted!
          </p>
          <Button fullwidth onClick={() => history.push("/submit")}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompleteModal;
