import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti'

const Celebration = props => {
  const { width, height } = useWindowSize()

  return (
    <Confetti
      width={width}
      height={height}
    />
  );
}

export default Celebration;
