import React from "react";
import Header from "../Components/Header";
import AppHeader from "../Components/layout/AppHeader";
import Container from "../Components/layout/Container";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import classes from "../styles/components/submit.module.scss";

const Submit = () => {
  return (
    <div className="home">
      <AppHeader header="Assemble" />
      <Container>
        {" "}
        <Header
          as="h1"
          color="primary"
          textAlign="centered"
          style={{ marginBottom: "30px" }}
        >
          Musical Sweatpants
        </Header>
        <div className={classes["content"]}>
          <div className={classes["instructions"]}>
            <Link className={classes["link"]} to="/">
              {"<-"} back to board{" "}
            </Link>
            <p className={classes["message"]}>
              <span style={{ fontWeight: "bold" }}>
                Look at you, all finished! Congrats!{" "}
              </span>{" "}
              Please take a moment to let us know how it went, and where we can
              find your amazing work. Make sure all of your work has been
              commited and merged into the main branch of your repo.
              <br />
              <br />
              We ask you to please make the repo private, and invite
              assemble-engineering to be a collaborator.
              <br />
              <br />
              If you feel like working past the 3 hours alotted, we get it, it's
              hard to stop- just please make sure you work on a different
              branch, and do not merge into main until after the hiring process
              is complete.
            </p>
            <Header
              as="h4"
              textAlign="centered"
              style={{ marginBottom: "100px" }}
            >
              Thank you for spending your time with us, we wish you the best!
            </Header>
          </div>

          <form
            class={classes["form"]}
            name="fedSubmission"
            method="post"
            // action="/completed/" can have a custom completion page if you want
          >
            <input type="hidden" name="form-name" value="fedSubmission" />
            <fieldset>
              <div>
                <label>Name:</label>
                <input type="text" name="name" />
              </div>
              <div>
                <label>Email:</label>
                <input type="text" name="email" />
              </div>
              <div>
                <label>Link to private GitHub repo:</label>
                <input type="text" name="repo" />
              </div>

              <div>
                <label>What went well?</label>
                <textarea name="whatWentWell"></textarea>
              </div>
              <div>
                <label>What was difficult?</label>
                <textarea name="whatWasDifficult"></textarea>
              </div>
              <div>
                <label>If you had more time, what would you do next?</label>
                <textarea name="whatWouldYouDoNext"></textarea>
              </div>
              <div>
                <label>
                  Is there anything else you want to share with us about the
                  tech screen or hiring process so far?
                </label>
                <textarea name="otherComments"></textarea>
              </div>
            </fieldset>

            <Button type="submit">Submit 🎉</Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Submit;
