import React from 'react';

export default class NotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>404 </h1>
        <h2>Page Not Found</h2>
        <p>Sorry, but the page you are looking for does not exist.</p>
      </div>
    );
  }
}
