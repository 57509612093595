import Checkbox from "./Checkbox";
import Modal from "./Modal";
import Flex from "./layout/Flex";
import Divider from "./Divider";
import Header from "./Header";

const TaskModal = (props) => {
  const changeAcStatus = (acId) => {
    const newAcs = [...props.acceptanceCriteria];

    newAcs.forEach((ac) => {
      if (ac.id === acId) {
        return (ac.complete = !ac.complete);
      }
      return ac;
    });

    const newTask = { ...props.tasks.find((task) => task.id === props.id) };

    newTask.acceptanceCriteria = newAcs;

    const newTasks = [...props.tasks];
    newTasks.forEach((task) => {
      if (task.id === props.id) {
        return (task = newTask);
      }
      return task;
    });

    props.setTasks(newTasks);
  };

  return (
    <Modal onClose={() => props.setModalVisible(false)} fullscreen>
      <div>
        <Header as="h4">
          {props.title} (
          <span style={{ fontStyle: "italic" }}>{props.status}</span>)
        </Header>
        <Divider style={{ marginBottom: "50px" }} />
        <div style={{ marginBottom: "50px" }}>
          <Header as="h5">Description</Header>
          <p>{props.desc}</p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <Header as="h5">Acceptance Criteria</Header>
          {props.acceptanceCriteria.map((criterion) => (
            <Checkbox
              key={criterion.id}
              id={criterion.id}
              checked={criterion.complete}
              onChange={() => changeAcStatus(criterion.id)}
              label={criterion.label}
            />
          ))}
        </div>
        <Header as="h5">Attachments</Header>
        {props.assets.map((asset, i) => (
          <a
            key={`download-asset-${i}`}
            style={{ display: "block", marginBottom: "10px" }}
            href={`https://frontend-tech-screen.s3.amazonaws.com/${asset}`}
            rel="noopener noreferrer"
            target="_blank"
          >{`${asset}`}</a>
        ))}
        <Flex wrap>
          {props.assets.map((asset, i) => (
            <Flex.Column key={`asset-${i}`} basis="50%">
              <img
                style={{
                  maxWidth: "100%",
                  boxShadow: "0 0 4px 3px rgba(0,0,0, .1)",
                }}
                src={`https://frontend-tech-screen.s3.amazonaws.com/${asset}`}
                alt=""
              />
            </Flex.Column>
          ))}
        </Flex>
      </div>
    </Modal>
  );
};

export default TaskModal;
