import { useDrop } from 'react-dnd'

const DropColumn = ({changeTaskStatus, status, children}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'card',
    drop(item) {
      changeTaskStatus(item.id, status)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  return (
    <div
      ref={drop}
      style={{ backgroundColor: isOver ? '#d9d9d9' : 'var(--off-white)', minHeight: "75vh", padding: '10px' }}>
      {children}
    </div>
  );
}

export default DropColumn;
